import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';
import profileImage1 from './assets/profile.svg';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaBuilding, FaUserTie, FaUser, FaDownload } from 'react-icons/fa';

const App = () => {
  const [contactInfo, setContactInfo] = useState({
    name: '',
    company: '',
    phone: '',
    email: '',
    website: '',
    address: '',
  });
  const [profileImage, setProfileImage] = useState(null);

  const fetchProfileImage = async (url) => {
    try {
      const response = await axios.post(`https://cdn.hayashirsha.com/${url}`, null, {
        responseType: 'arraybuffer',
      });

      const base64 = btoa(
        new Uint8Array(response.data)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
      return `data:image/jpeg;base64,${base64}`;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const currentUrl = window.location.href; 
        const url = new URL(currentUrl);
        const shareId = url.searchParams.get('share_id');

        // Fetch contact information using the share_id
        const response = await axios.get(`https://apiv2.hayashirsha.com/shared?share_id=${shareId}`);
        const contactData = response.data.data;
        setContactInfo(contactData);

        if (contactData.photo_url) {
          const profileImgBase64 = await fetchProfileImage(contactData.photo_url);
          setProfileImage(profileImgBase64);
        }

      } catch (error) {
        console.error('Error fetching contact info:', error);
      }
    };

    fetchContactInfo();
  }, []);

  const vCard = `
BEGIN:VCARD
VERSION:3.0
FN:${contactInfo.display_name}
ORG:${contactInfo.company_name}
TEL:${contactInfo.phone_number}
EMAIL:${contactInfo.email}
ADR:;;${contactInfo.city_name}
END:VCARD
  `;

  const downloadVCard = () => {
    const blob = new Blob([vCard], { type: 'text/vcard' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${contactInfo.name}.vcf`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="container">
      <div className="profile-card">
        <div className="profile-header">
          <div className="profile-pic">
            {profileImage ? (
              <img src={profileImage} alt="Profile" style={{width: "100px", height: "100px"}}/>
            ) : (
              <img src={profileImage1} alt="Profile" />
            )}
          </div>
        </div>
        <div className="profile-info">
          <h1 className="name">{contactInfo.display_name || "Name Here"}</h1>
          <p className="company">{contactInfo.company_name || "Company Here"}</p>
        </div>

        <div className="contact-container">
          <div className="contact-details">
            <h2 className="contact-heading">Bio</h2>
            <p>{contactInfo.bio || "No bio available."}</p>
            <h2 className="contact-heading">Email</h2>
            <p className="icon-text">
              <FaEnvelope style={{ color: '#007bff' }} />
              <span>{contactInfo.email || "Not Provided"}</span>
            </p>
            <h2 className="contact-heading">Phone</h2>
            <p className="icon-text">
              <FaPhone style={{ transform: 'scaleX(-1)', color: '#007bff' }} />
              <span>{contactInfo.phone_number || "Not Provided"}</span>
            </p>
            <h2 className="contact-heading">Address</h2>
            <p className="icon-text">
              <FaMapMarkerAlt style={{ color: '#007bff' }} />
              <span>{contactInfo.city_name || "Not Provided"}</span>
            </p>
            <h2 className="contact-heading">Department</h2>
            <p className="icon-text">
              <FaBuilding style={{ color: '#007bff' }} />
              <span>{contactInfo.department || "Not Provided"}</span>
            </p>
            <h2 className="contact-heading">Designation</h2>
            <p className="icon-text">
              <FaUserTie style={{ color: '#007bff' }} />
              <span>{contactInfo.designation || "Not Provided"}</span>
            </p>
          </div>
        </div>
        <button onClick={downloadVCard} className="save-button">
          <FaDownload style={{ marginRight: '8px' }} /> {/* Add the download icon */}
          Save Contact
        </button>
      </div>
    </div>

  );
};

export default App;
